import React from 'react'

type Props = React.SVGProps<SVGSVGElement>

const FiveSeasonsYachtingLogo = (props: Props) => (
  <svg
    viewBox="0 0 637.93335 57.706669"
    version="1.1"
    width={374}
    height={34}
    {...props}
  >
    <g
      transform="matrix(1.3333333,0,0,-1.3333333,0,57.706667)"
      id="g10"
    >
      <g
        transform="scale(0.1)"
        id="Logo"
        fill="currentColor"
      >
        <path
          id="path14"
          d="m 1265.67,129.5 v -19 H 1194.8 V 74.5664 h 70.87 V 55.6563 H 1194.8 V 19.7227 h 70.87 V 0.71875 H 1175.8 V 129.5 h 89.87"
        />
        <path
          id="path16"
          d="m 1361.76,33.9492 c 0,3.543 -0.15,5.9766 -0.45,7.2969 -0.9,4.1406 -3,7.5625 -6.3,10.2656 -3.36,2.7617 -7.24,4.1446 -11.62,4.1446 h -22.42 c -4.33,0 -8.17,-1.3829 -11.53,-4.1446 -3.3,-2.7031 -5.43,-6.125 -6.39,-10.2656 -0.24,-1.3203 -0.36,-3.7539 -0.36,-7.2969 V 0.71875 h -19.01 V 33.5898 c 0,4.4454 0.15,7.625 0.45,9.5469 1.39,8.9453 5.53,16.3906 12.43,22.336 -6.78,6.1796 -10.68,13.8359 -11.71,22.9609 -0.3,2.7031 -0.45,5.5234 -0.45,8.4648 V 129.5 h 18.92 V 95.1875 c 0,-2.6406 0.06,-4.4102 0.18,-5.3125 0.66,-4.082 2.49,-7.5352 5.49,-10.3555 3,-2.8828 6.57,-4.5312 10.72,-4.9531 h 25.03 c 4.08,0.4219 7.66,2.0703 10.72,4.9531 3,2.8203 4.8,6.2735 5.4,10.3555 0.12,0.9023 0.18,2.6719 0.18,5.3125 V 129.5 h 19 V 96.8984 c 0,-2.9414 -0.15,-5.7617 -0.45,-8.4648 -1.08,-9.125 -4.98,-16.7813 -11.7,-22.9609 6.84,-5.9454 10.95,-13.3907 12.33,-22.336 0.3,-1.9219 0.45,-5.1015 0.45,-9.5469 V 0.71875 h -18.91 V 33.9492"
        />
        <path
          id="path18"
          d="m 1461.63,73.8477 c 7.57,0.7187 13.33,2.9687 17.3,6.7539 3.6,3.4218 5.4,7.625 5.4,12.6054 0,7.508 -3.42,12.789 -10.27,15.852 -2.1,0.961 -5.34,1.441 -9.72,1.441 h -45.3 l 0.45,-36.6523 z m 1,55.7423 c 6.6,0 11.61,-0.512 15.03,-1.531 7.57,-2.34 13.72,-6.696 18.47,-13.059 4.86,-6.422 7.29,-13.687 7.29,-21.793 0,-10.0859 -3.66,-18.8203 -10.99,-26.2031 -7.38,-7.5078 -15.97,-11.2891 -25.75,-11.3476 h -47.19 V 0.71875 h -19 V 129.5 h 19 l 43.14,0.09"
        />
        <path
          id="path20"
          d="M 1539.53,129.5 V 19.7227 h 70.88 V 0.71875 h -89.88 V 129.5 h 19"
        />
        <path
          id="path22"
          d="m 1720.19,32.6016 c 9.06,9.0039 13.59,19.9023 13.59,32.6875 0,12.789 -4.53,23.7148 -13.59,32.7812 -9.07,9.0667 -20,13.5977 -32.78,13.5977 -12.79,0 -23.69,-4.531 -32.69,-13.5977 -9.07,-9.0664 -13.6,-19.9922 -13.6,-32.7812 0,-12.7852 4.53,-23.6836 13.6,-32.6875 9,-9.0664 19.9,-13.5977 32.69,-13.5977 12.78,0 23.71,4.5313 32.78,13.5977 z M 1687.41,0 c -18.02,0 -33.42,6.36328 -46.2,19.0938 -12.73,12.7851 -19.1,28.1835 -19.1,46.1953 0,18.0742 6.37,33.4726 19.1,46.1989 12.78,12.789 28.18,19.184 46.2,19.184 18.07,0 33.47,-6.395 46.19,-19.184 12.79,-12.7263 19.19,-28.1247 19.19,-46.1989 0,-18.0118 -6.4,-33.4102 -19.19,-46.1953 C 1720.88,6.36328 1705.48,0 1687.41,0"
        />
        <path
          id="path24"
          d="m 1831.86,74.9258 c 7.02,0 12.51,1.8906 16.48,5.6758 3.6,3.4218 5.4,7.625 5.4,12.6054 0,7.508 -3.42,12.789 -10.27,15.852 -2.34,1.078 -6.09,1.621 -11.25,1.621 L 1788.9,110.5 V 74.7461 Z m 0.18,54.6642 c 6.6,0 11.61,-0.512 15.04,-1.531 7.56,-2.34 13.71,-6.696 18.46,-13.059 4.86,-6.422 7.29,-13.687 7.29,-21.793 0,-13.8672 -6.12,-24.5547 -18.37,-32.0586 12.13,-10.6875 18.25,-24.0742 18.37,-40.164 V 0.71875 h -19 V 20.9844 c -0.12,9.3633 -3.42,17.4101 -9.91,24.1328 -6.54,6.6641 -14.47,10.207 -23.77,10.6289 L 1788.9,55.6563 V 0.71875 h -19 V 129.5 h 19 l 43.14,0.09"
        />
        <path
          id="path26"
          d="m 1979.82,129.5 v -19 h -70.88 V 74.5664 h 70.88 V 55.6563 h -70.88 V 19.7227 h 70.88 V 0.71875 h -89.88 V 129.5 h 89.88"
        />
        <path
          id="path28"
          d="m 2041.06,110.5 v 19 h 96.63 v -19 h -38.82 V 0.71875 h -19 V 110.5 h -38.81"
        />
        <path
          id="path30"
          d="M 2172,129.5 V 74.5664 h 68.71 V 129.5 h 19 V 0.71875 h -19 V 55.6563 H 2172 V 0.71875 h -19 V 129.5 h 19"
        />
        <path
          id="path32"
          d="m 2369.4,129.5 v -19 h -70.87 V 74.5664 h 70.87 V 55.6563 h -70.87 V 19.7227 h 70.87 V 0.71875 h -89.88 V 129.5 h 89.88"
        />
        <path
          id="path34"
          d="m 2524.48,129.41 h 19 V 54.4844 c -0.12,-14.8281 -5.46,-27.4961 -16.03,-38.0039 -10.57,-10.50784 -23.27,-15.76175 -38.09,-15.76175 -14.89,0 -27.62,5.25391 -38.19,15.76175 -10.57,10.5078 -15.91,23.1758 -16.03,38.0039 V 129.41 h 19 V 53.043 c 0.54,-9.3047 4.18,-17.1719 10.9,-23.5938 6.78,-6.4844 14.89,-9.7265 24.32,-9.7265 9.6,0 17.83,3.3906 24.67,10.1757 6.84,6.7852 10.33,14.9805 10.45,24.586 V 129.41"
        />
        <path
          id="path36"
          d="m 2653.62,76.457 c -0.12,9.7852 -3.63,18.1016 -10.54,24.945 -6.96,6.844 -15.31,10.266 -25.03,10.266 -9.49,0 -17.66,-3.27 -24.5,-9.816 -6.84,-6.4848 -10.54,-14.4379 -11.08,-23.8637 V 0.71875 h -19.18 V 76.457 c 0.12,15.0703 5.52,27.887 16.21,38.453 10.63,10.567 23.48,15.852 38.55,15.852 15,0 27.85,-5.285 38.54,-15.852 10.69,-10.566 16.09,-23.3827 16.21,-38.453 V 0.71875 h -19.18 V 76.457"
        />
        <path
          id="path38"
          d="m 2782.49,129.5 v -19 h -70.88 V 74.5664 h 70.88 V 55.6563 h -70.88 V 19.7227 h 70.88 V 0.71875 h -89.88 V 129.5 h 89.88"
        />
        <path
          id="path40"
          d="m 2878.58,33.9492 c 0,3.543 -0.15,5.9766 -0.45,7.2969 -0.9,4.1406 -3,7.5625 -6.31,10.2656 -3.36,2.7617 -7.23,4.1446 -11.61,4.1446 h -22.43 c -4.32,0 -8.16,-1.3829 -11.53,-4.1446 -3.3,-2.7031 -5.43,-6.125 -6.39,-10.2656 -0.24,-1.3203 -0.36,-3.7539 -0.36,-7.2969 V 0.71875 h -19 V 33.5898 c 0,4.4454 0.15,7.625 0.45,9.5469 1.38,8.9453 5.52,16.3906 12.43,22.336 -6.79,6.1796 -10.69,13.8359 -11.71,22.9609 -0.3,2.7031 -0.45,5.5234 -0.45,8.4648 V 129.5 h 18.91 V 95.1875 c 0,-2.6406 0.06,-4.4102 0.18,-5.3125 0.66,-4.082 2.49,-7.5352 5.49,-10.3555 3.01,-2.8828 6.58,-4.5312 10.72,-4.9531 h 25.04 c 4.08,0.4219 7.65,2.0703 10.71,4.9531 3.01,2.8203 4.81,6.2735 5.41,10.3555 0.12,0.9023 0.18,2.6719 0.18,5.3125 V 129.5 h 19 V 96.8984 c 0,-2.9414 -0.15,-5.7617 -0.45,-8.4648 -1.08,-9.125 -4.98,-16.7813 -11.71,-22.9609 6.85,-5.9454 10.96,-13.3907 12.34,-22.336 0.3,-1.9219 0.45,-5.1015 0.45,-9.5469 V 0.71875 h -18.91 V 33.9492"
        />
        <path
          id="path42"
          d="m 2978.45,73.8477 c 7.56,0.7187 13.33,2.9687 17.29,6.7539 3.6,3.4218 5.4,7.625 5.4,12.6054 0,7.508 -3.42,12.789 -10.26,15.852 -2.1,0.961 -5.35,1.441 -9.73,1.441 h -45.3 l 0.45,-36.6523 z m 0.99,55.7423 c 6.6,0 11.62,-0.512 15.04,-1.531 7.56,-2.34 13.72,-6.696 18.46,-13.059 4.86,-6.422 7.3,-13.687 7.3,-21.793 0,-10.0859 -3.67,-18.8203 -10.99,-26.2031 -7.39,-7.5078 -15.97,-11.2891 -25.76,-11.3476 H 2936.3 V 0.71875 h -19 V 129.5 h 19 l 43.14,0.09"
        />
        <path
          id="path44"
          d="m 3127.21,129.5 v -19 h -70.86 V 74.5664 h 70.86 V 55.6563 h -70.86 V 19.7227 h 70.86 V 0.71875 h -89.86 V 129.5 h 89.86"
        />
        <path
          id="path46"
          d="m 3207.91,0 c -18.08,0 -33.48,6.36328 -46.2,19.0938 -12.79,12.7851 -19.19,28.2148 -19.19,46.289 0,18.0078 6.4,33.4063 19.19,46.1952 12.72,12.731 28.12,19.094 46.2,19.094 13.14,0 25.15,-3.602 36.02,-10.809 10.68,-7.082 18.58,-16.449 23.68,-28.0974 h -21.7 c -9.25,13.2694 -21.91,19.9024 -38,19.9024 -12.79,0 -23.72,-4.531 -32.79,-13.5977 -9.06,-9.0039 -13.59,-19.9023 -13.59,-32.6875 0,-12.8515 4.53,-23.7773 13.59,-32.7812 9.07,-9.0664 20,-13.5977 32.79,-13.5977 16.09,0 28.75,6.6328 38,19.8984 h 21.7 c -5.1,-11.6445 -13,-21.0117 -23.68,-28.0937 C 3233.06,3.60156 3221.05,0 3207.91,0"
        />
        <path
          id="path48"
          d="m 3280.22,110.5 v 19 h 96.63 v -19 h -38.81 V 0.71875 h -19.01 V 110.5 h -38.81"
        />
        <path
          id="path50"
          d="m 3482.04,129.5 v -19 h -70.88 V 74.5664 h 70.88 V 55.6563 h -70.88 V 19.7227 h 70.88 V 0.71875 h -89.88 V 129.5 h 89.88"
        />
        <path
          id="path52"
          d="m 3544.62,19.7227 c 8.89,0 16.61,1.9804 23.15,5.9414 6.78,4.082 12.13,9.5468 16.03,16.3906 4.08,7.0234 6.12,14.6484 6.12,22.875 0,10.6875 -3.33,20.2031 -9.99,28.5469 -6.55,8.2264 -14.89,13.5704 -25.04,16.0314 -2.64,0.66 -6.27,0.992 -10.9,0.992 h -24.94 V 19.7227 Z M 3543,129.5 c 5.47,0 9.82,-0.328 13.06,-0.988 15.13,-2.824 27.68,-10.176 37.65,-22.067 10.02,-12.0075 15.03,-25.8434 15.03,-41.5153 0,-11.8281 -2.97,-22.8125 -8.91,-32.9609 -5.82,-9.7852 -13.69,-17.5313 -23.6,-23.23442 -9.3,-5.34375 -19.93,-8.01563 -31.88,-8.01563 h -44.3 V 129.5 H 3543"
        />
        <path
          id="path54"
          d="m 4784.54,258.629 h -142.55 c -36.65,0 -64.99,7.457 -85.02,22.383 -20.03,14.914 -30.04,36.078 -30.04,63.488 0,27.402 10.01,48.566 30.04,63.488 20.03,14.922 48.37,22.379 85.02,22.379 h 142.55 v -30.406 h -139.88 c -24.32,0 -43.1,-4.867 -56.31,-14.594 -13.22,-9.73 -19.83,-23.437 -19.83,-41.109 0,-17.684 6.61,-31.383 19.83,-41.113 13.21,-9.731 31.99,-14.594 56.31,-14.594 h 102.42 v 43.582 h -61.23 l 16.52,27.25 h 82.17 V 258.629"
        />
        <path
          id="path56"
          d="m 0,430.367 h 209.691 v -30.89 H 38.6797 V 258.629 H 0 v 171.738"
        />
        <path
          id="path58"
          d="m 276.883,258.672 h -40.379 v 171.656 h 40.379 V 258.672"
        />
        <path
          id="path60"
          d="m 293.492,430.367 h 44.516 l 87.086,-140.844 h 14.594 l 87.335,140.844 h 42.813 L 463.043,258.629 H 400.285 L 293.492,430.367"
        />
        <path
          id="path62"
          d="M 592.215,430.367 H 812.852 V 401.422 H 631.133 v -87.086 c 0,-9.41 3.004,-16.223 9.004,-20.434 5.996,-4.218 15.57,-6.328 28.703,-6.328 H 812.852 V 258.629 H 663.246 c -24.812,0 -42.851,4.34 -54.129,13.016 -11.273,8.675 -16.902,22.582 -16.902,41.714 v 117.008"
        />
        <path
          id="path64"
          d="m 902.859,289.523 h 151.791 c 11.51,0 20.27,1.821 26.27,5.473 6,3.652 9.01,8.961 9.01,15.934 0,6.808 -3.01,12.043 -9.01,15.691 -6,3.649 -14.76,5.469 -26.27,5.469 h -77.353 c -24.004,0 -42.414,4.262 -55.223,12.773 -12.812,8.516 -19.215,20.637 -19.215,36.367 0,15.731 6.403,27.856 19.215,36.368 12.809,8.515 31.219,12.769 55.223,12.769 h 151.793 v -30.89 H 979.973 c -23.844,0 -35.762,-6.653 -35.762,-19.946 0,-13.304 11.918,-19.949 35.762,-19.949 h 77.357 c 24,0 42.4,-4.34 55.21,-13.016 12.82,-8.675 19.23,-21.203 19.23,-37.582 0,-16.218 -6.37,-28.664 -19.1,-37.339 -12.74,-8.676 -31.18,-13.016 -55.34,-13.016 H 902.859 v 30.894"
        />
        <path
          id="path66"
          d="m 1162.9,430.367 h 220.64 v -28.945 h -181.72 v -87.086 c 0,-9.41 3,-16.223 9,-20.434 6,-4.218 15.57,-6.328 28.7,-6.328 h 144.02 v -28.945 h -149.61 c -24.81,0 -42.85,4.34 -54.13,13.016 -11.27,8.675 -16.9,22.582 -16.9,41.714 v 117.008"
        />
        <path
          id="path68"
          d="M 1702.2,289.523 H 1854 c 11.51,0 20.27,1.821 26.27,5.473 5.99,3.652 9,8.961 9,15.934 0,6.808 -3.01,12.043 -9,15.691 -6,3.649 -14.76,5.469 -26.27,5.469 h -77.36 c -24,0 -42.41,4.262 -55.22,12.773 -12.81,8.516 -19.22,20.637 -19.22,36.367 0,15.731 6.41,27.856 19.22,36.368 12.81,8.515 31.22,12.769 55.22,12.769 h 151.79 v -30.89 h -149.11 c -23.85,0 -35.77,-6.653 -35.77,-19.946 0,-13.304 11.92,-19.949 35.77,-19.949 h 77.35 c 24,0 42.41,-4.34 55.22,-13.016 12.81,-8.675 19.22,-21.203 19.22,-37.582 0,-16.218 -6.37,-28.664 -19.1,-37.339 -12.73,-8.676 -31.17,-13.016 -55.34,-13.016 H 1702.2 v 30.894"
        />
        <path
          id="path70"
          d="m 2177.41,301.809 c 12.73,9.812 19.09,24.039 19.09,42.691 0,18.809 -6.41,33.082 -19.21,42.813 -12.82,9.73 -31.47,14.593 -55.95,14.593 h -48.17 c -24.49,0 -43.1,-4.863 -55.83,-14.593 -12.73,-9.731 -19.09,-24.004 -19.09,-42.813 0,-18.652 6.36,-32.879 19.09,-42.691 12.73,-9.813 31.34,-14.719 55.83,-14.719 h 48.17 c 24.65,0 43.33,4.906 56.07,14.719 z m -191.45,-22.868 c -19.7,15.161 -29.55,37.012 -29.55,65.559 0,28.539 9.85,50.395 29.55,65.555 19.71,15.164 48.2,22.746 85.51,22.746 h 51.57 c 37.3,0 65.8,-7.582 85.5,-22.746 19.71,-15.16 29.56,-37.016 29.56,-65.555 0,-28.547 -9.85,-50.398 -29.56,-65.559 -19.7,-15.164 -48.2,-22.746 -85.5,-22.746 h -51.57 c -37.31,0 -65.8,7.582 -85.51,22.746"
        />
        <path
          id="path72"
          d="m 2269.23,430.367 h 62.28 l 134.03,-140.113 h 8.51 v 140.113 h 37.47 V 258.629 h -62.51 l -134.04,140.117 h -8.52 V 258.629 h -37.22 v 171.738"
        />
        <path
          id="path74"
          d="m 2543.86,289.523 h 151.8 c 11.51,0 20.27,1.821 26.27,5.473 6,3.652 9.01,8.961 9.01,15.934 0,6.808 -3.01,12.043 -9.01,15.691 -6,3.649 -14.76,5.469 -26.27,5.469 h -77.36 c -24,0 -42.41,4.262 -55.21,12.773 -12.82,8.516 -19.23,20.637 -19.23,36.367 0,15.731 6.41,27.856 19.23,36.368 12.8,8.515 31.21,12.769 55.21,12.769 h 151.8 v -30.89 h -149.12 c -23.84,0 -35.76,-6.653 -35.76,-19.946 0,-13.304 11.92,-19.949 35.76,-19.949 h 77.36 c 23.99,0 42.41,-4.34 55.22,-13.016 12.8,-8.675 19.22,-21.203 19.22,-37.582 0,-16.218 -6.38,-28.664 -19.1,-37.339 -12.74,-8.676 -31.18,-13.016 -55.34,-13.016 h -154.48 v 30.894"
        />
        <path
          id="path76"
          d="m 3008.73,315.793 v -57.164 h -40.13 v 57.164 l -117.02,114.574 h 50.37 l 86.11,-83.433 h 3.64 l 86.12,83.433 h 48.16 L 3008.73,315.793"
        />
        <path
          id="path78"
          d="m 3609.58,399.477 h -125.53 c -24.8,0 -43.7,-4.707 -56.67,-14.11 -12.98,-9.406 -19.47,-23.027 -19.47,-40.867 0,-17.844 6.49,-31.465 19.47,-40.867 12.97,-9.406 31.87,-14.11 56.67,-14.11 h 125.53 v -30.894 h -127.23 c -37.62,0 -66.37,7.332 -86.23,22.016 -19.86,14.675 -29.8,35.957 -29.8,63.855 0,27.887 9.94,49.176 29.8,63.855 19.86,14.672 48.61,22.012 86.23,22.012 h 127.23 v -30.89"
        />
        <path
          id="path80"
          d="m 3644.61,430.367 h 40.14 v -69.566 h 161.77 v 69.566 h 40.37 V 258.629 h -40.37 v 71.273 h -161.77 v -71.273 h -40.14 v 171.738"
        />
        <path
          id="path82"
          d="m 4014.36,399.477 h -103.38 v 30.89 h 247.14 v -30.89 H 4054.49 V 258.629 h -40.13 v 140.848"
        />
        <path
          id="path84"
          d="m 4253.75,430.367 h 62.27 l 134.03,-140.113 h 8.51 v 140.113 h 37.46 V 258.629 h -62.5 l -134.05,140.117 h -8.51 V 258.629 h -37.21 v 171.738"
        />
        <path
          id="path86"
          d="m 4222.58,258.672 h -40.38 v 171.656 h 40.38 V 258.672"
        />
        <path
          id="path88"
          d="M 1684.21,258.629 1577.9,430.367 h -67.63 l -106.3,-171.738 h 42.08 l 86.12,142.066 h 22.13 l 49.51,-81.679 h -60.49 L 1526.8,291.77 h 93.53 l 20.09,-33.141 h 43.79"
        />
        <path
          id="path90"
          d="M 91.5195,359.379 75.0039,332.133 h 98.6951 v 27.246 H 91.5195"
        />
        <path
          id="path92"
          d="M 694.445,359.379 677.93,332.133 h 98.695 v 27.246 h -82.18"
        />
        <path
          id="path94"
          d="m 1265.12,359.379 -16.52,-27.246 h 98.7 v 27.246 h -82.18"
        />
        <path
          id="path96"
          d="m 3367.3,258.629 -106.31,171.738 h -67.62 l -106.3,-171.738 h 42.08 l 86.11,142.066 h 22.14 l 49.51,-81.679 h -60.5 L 3209.9,291.77 h 93.52 l 20.09,-33.141 h 43.79"
        />
      </g>
    </g>
  </svg>
)

export default FiveSeasonsYachtingLogo
